<template>
  <div class="d-flex align-items-center mr-4 w-100">
    <b-form-group :label="translations.filters.billable_client">
      <b-form-select v-model="filter_set.billable_client_id"
        :disabled="loading"
        :options="filter_options.clients"/>
      </b-form-group>
    <b-form-group :label="translations.filters.status" class="flex-grow-1 mr-4 status-column">
      <multiselect
        v-model="filter_set.status"
        :options="filter_options.status"
        :multiple="true"
        :showLabels="false"
        :searchable="false"
        track-by="value"
        label="text"
        :disabled="loading"/>
    </b-form-group>
    <b-form-group :label="translations.filters.external_invoice_id" class="flex-grow-1 mr-4">
      <b-form-input
        type="text"
        v-model="filter_set.external_id"/>
    </b-form-group>
    <b-button
      variant="primary"
      class="mt-3 ml-2"
      @click="applyFilters">{{ translations.filters.search }}</b-button>
  </div>
</template>

<script>
import translations from '@/translations';
import Multiselect from 'vue-multiselect';
import { INVOICE_STATUS } from '@/constants/finance';

export default {
  components: {
    Multiselect,
  },
  name: 'InvoiceFilters',
  data() {
    return {
      loading: false,
      translations: translations.finance.invoices,
      filter_set: {
        billable_client_id: null,
        external_id: null,
        status: null,
      },
      filter_options: {
        clients: [
          { value: '', text: translations.finance.invoices.filters.default_billable_client },
        ],
        status: [
          { value: INVOICE_STATUS.PENDING, text: 'Pending' },
          { value: INVOICE_STATUS.AWAITING_PROCESSING, text: 'Awaiting Processing' },
          { value: INVOICE_STATUS.PROCESSING, text: 'Processing' },
          { value: INVOICE_STATUS.SUBMITTED, text: 'Submitted' },
          { value: INVOICE_STATUS.REJECTED, text: 'Rejected' },
          { value: INVOICE_STATUS.FAILED, text: 'Failed' },
        ],
      },
    };
  },
  beforeMount() {
    this.loadClients();
  },
  methods: {
    applyFilters() {
      const status = this.filter_set.status?.map(element => element.value);
      const payload = {
        billable_client_id: this.filter_set.billable_client_id,
        external_id: this.filter_set.external_id,
        status,
      };

      this.$emit('apply-filters', payload);
    },
    async loadClients() {
      this.loading = true;
      try {
        const { data } = await this.$store.dispatch('Financial/getFilters');
        const responseFilters = data
          .map(({ id: value, display_name: displayName }) => ({ value, text: `${displayName} (ID: ${value})` }));

        this.filter_options.clients = [ ...this.filter_options.clients, ...responseFilters ];
        this.loading = false;
      } catch (_) {
        this.$noty.error(this.translations.error_loading_filters);
      }
    },
  },
};
</script>

<style>
.status-column {
  max-width: 650px;
}
</style>
