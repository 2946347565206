<template>
  <b-container class="mt-4" fluid>
    <b-row>
      <b-col class="d-flex">
        <h3>{{ translations.list_page_title }}</h3>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button variant="primary"
          @click="pushToNetsuite">
          <feather type="arrow-up-circle"></feather>
          {{ translations.push_to_netsuite }}
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col class="d-flex">
        <invoice-filters @apply-filters="applyFilters"/>
      </b-col>
    </b-row>

    <financial-list
      v-model="currentPage"
      :header-data="TABLE_HEADERS"
      :data="invoices"
      :events="TABLE_EVENTS"
      itemRefKey='id'
      :disabled="loadingPage"
      :items-per-page="itemsPerPage"
      @page-changed="loadInvoicesList"
      :total="total"
      :no-items-message="translations.invoices_table.empty_invoice_list"
      @row-details="() => {}"/>

  </b-container>
</template>

<script>
import FinancialList, {
  associateHeaderDataItem,
  generateCustomComponentColumn,
} from '@/components/Financial/FinancialList.vue';
import translations from '@/translations';
import { INVOICE_STATUS } from '@/constants/finance';
import { formatDate, formatDatetime } from '@/helpers/finance';
import InvoiceFilters from './InvoiceFilters.vue';

export default {
  components: {
    InvoiceFilters,
    FinancialList,
  },
  name: 'FinanceInvoicesList',
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.invoices_table.invoice_id, 'id'),
      associateHeaderDataItem(this.translations.invoices_table.external_id, 'external_id'),
      associateHeaderDataItem(this.translations.invoices_table.billable_client_id, 'client_id'),
      associateHeaderDataItem(this.translations.invoices_table.billable_client, 'client_name'),
      associateHeaderDataItem(this.translations.invoices_table.start_date, 'start_date'),
      associateHeaderDataItem(this.translations.invoices_table.end_date, 'end_date'),
      associateHeaderDataItem(this.translations.invoices_table.created_at, 'created_at'),
      associateHeaderDataItem(this.translations.invoices_table.billing_type, 'billing_type'),
      associateHeaderDataItem(this.translations.invoices_table.payer, 'payer.payer_name'),
      associateHeaderDataItem(this.translations.invoices_table.sword_provider, 'billing_provider.name'),
      associateHeaderDataItem(this.translations.invoices_table.status, 'formatted_status'),
    ];
    this.TABLE_EVENTS = {
      'row-details': {
        title: translations.finance.financial_list.icons.details,
        icon: 'eye',
        redirectLink: id => `/finance/invoices/${id}`,
      },
    };
  },
  data() {
    return {
      translations: translations.finance.invoices,
      invoices: [],
      loadingPage: true,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      appliedFilters: {},
    };
  },
  computed: {
    filters: {
      get() {
        return {
          ...this.appliedFilters,
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          order_by: 'created_at',
          order_descending: true,
        };
      },
    },
  },
  async beforeMount() {
    await this.loadInvoicesList();
  },
  methods: {
    applyFilters(filters) {
      this.currentPage = 1;
      this.appliedFilters = filters;
      this.loadInvoicesList();
    },
    async pushToNetsuite() {
      try {
        const invoiceIDs = [];
        let updateCurrentPage = false;
        if (this.filters.billable_client_id || this.filters.status?.length > 0 || this.filters.external_id) {
          this.invoices.forEach(invoice => {
            if (invoice.status === INVOICE_STATUS.PENDING) {
              invoiceIDs.push(invoice.id);
            }
          });

          if (invoiceIDs.length <= 0) {
            return this.$noty.error(this.translations.errors.no_invoices_pushed_to_netsuite);
          }
          updateCurrentPage = true;
        }

        await this.$store.dispatch('Financial/pushToNetsuite', { invoice_ids: invoiceIDs });
        this.$noty.success(this.translations.successfully_pushed_to_netsuite);
        if (updateCurrentPage) this.currentPage = 1;
        return this.loadInvoicesList();
      } catch (_) {
        return this.$noty.error(this.translations.errors.pushing_to_netsuite);
      }
    },
    async loadInvoicesList() {
      this.loadingPage = true;
      const searchFilters = { ...this.filters };
      const invoicesResponse = await this.$store.dispatch('Financial/getInvoices', searchFilters);

      if (!invoicesResponse) {
        this.total = 0;
        this.invoices = [];
      } else {
        this.total = invoicesResponse.total;
        this.invoices = invoicesResponse.invoices;
      }

      this.invoices.forEach(invoice => {
        const propsData = {
          variant: this.formatStatusLabel(invoice.status),
        };
        invoice.formatted_status = generateCustomComponentColumn('BBadge', propsData, invoice.status);

        invoice.created_at = formatDatetime(invoice.created_at);
        invoice.start_date = formatDate(invoice.start_date);
        invoice.end_date = formatDate(invoice.end_date);
      });
      this.loadingPage = false;
    },
    formatStatusLabel(status) {
      if (status?.toLowerCase() === INVOICE_STATUS.SUBMITTED) {
        return 'success';
      }
      if (status?.toLowerCase() === INVOICE_STATUS.AWAITING_PROCESSING || status?.toLowerCase() === INVOICE_STATUS.PROCESSING) {
        return 'primary';
      }
      if (status?.toLowerCase() === INVOICE_STATUS.FAILED) {
        return 'danger';
      }
      return 'secondary';
    },
  },
};
</script>
